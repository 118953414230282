import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Support = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`管理サポート | ${title}`}</title>
        <meta
          name="description"
          content="メーカー自身の手で管理サポート業務を行うことにより、つねに迅速で適切な駐車場管理を実現。北海道から沖縄まで日本全国対応可能です。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>管理サポート</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>管理サポート</li>
        </div>
      </div>

      <div className="main-wrapper support">
        <div className="row">
          <div className="container top">
            <h4>
              メーカー自身の手で管理サポート業務を行うことにより、つねに迅速で適切な駐車場管理を実現。北海道から沖縄まで日本全国対応可能です。
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>サポートエリア</h2>
          </div>
        </div>

        <div className="row">
          <div className="container cont-01">
            <div className="separate-area">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/common/service-support-01.webp"
                />
                <img
                  className="support-01"
                  src="/assets/images/common/service-support-01.png"
                  height="678"
                  alt=""
                />
              </picture>

              <p className="right-cont">
                事業地の最寄り提携先との連携により、日本全国（沖縄本島・宮古島を含む）を幅広くカバーするサポート体制を実現しています。
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>コールセンター（24時間・365日対応）</h2>
          </div>
        </div>

        <div className="row">
          <div className="container cont-02">
            <p>
              コールセンターには、専任スタッフが常時待機しており、駐車場ご利用のお客様からの問い合わせに的確に対応。必要に応じて、最寄りの提携先からサポートエンジニアを急行させます。また、遠隔監視システム、セキュリティカメラシステムなど、先進のサポートシステムを駆使し、お客様に迅速かつ質の高いサービスを提供いたします。
            </p>

            <div className="separate-area">
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/common/service-support-02.webp"
                />
                <img
                  className="support-02"
                  src="/assets/images/common/service-support-02.png"
                  height="505"
                  alt=""
                />
              </picture>

              <ul className="small-points">
                <li>24時間・365日対応</li>
                <li>駐車場ご利用のお客様との直接対応</li>
                <li>AI自動チャットでのお客様対応サポート（2020/08～）</li>
                <li>障害対応</li>
                <li>不正出庫管理（ナンバー認識システム）</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>保守</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-03">
            <p>
              機器の定期点検を行うことにより、事故を未然に防ぎ、修繕費を抑えます。
              <br />
              セキュリティカメラの保守、画像処理、提出作業も実施しています。
            </p>

            <ul className="small-points">
              <li>機器の性能維持</li>
              <li>故障、トラブルの未然防止</li>
              <li>機器の修理交換</li>
              <li>画像データの収集、提出</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>集金・清掃</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-04">
            <p>
              売上金の回収、集計、釣り銭の補充、指定口座への振り込み、駐車場の清掃もすべて当社で行います。
            </p>

            <ul className="small-points">
              <li>集金業務代行</li>
              <li>駐車券、ロール紙の補充</li>
              <li>定期清掃</li>
              <li>粗大ゴミ撤去</li>
            </ul>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Support
